if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}


(function(){
    // Remove empty <p> tags created by tinymce
    const paragraph = document.querySelectorAll('p') || [];
	paragraph.forEach( e => (e.innerHTML.trim() === '') && e.parentNode.removeChild(e));

    $('.sym-slides').slick();

    if('.slick-hidden') {
        $('.slick-hidden').parent('.sym-slides-arrows-container').hide();
        //$('.slick-hidden').parents('.each-item__btn--no-line-detail').css('margin-left','30px');
    }

    $('.expand-content').click(function() {
        event.preventDefault();
        $(this).next().slideDown();
    });

    $('.closemore').click(function() { 
        event.preventDefault();
        $(this).parent().slideUp(); 
    });

    $('.fancybox-copy').fancybox({
        wrapCSS : 'fancybox-copy-holder',
        maxWidth: 850
    });

    //Accordion controls
    //$('.accordion-item__trigger .accordion-item__icon span').html('View more');
    //$('.accordion-item__content').slideUp();

    //$('.accordion-item__trigger').click(function(e) {
    //    e.preventDefault();
    //    var $this = $(this);
    //
    //    $this.parent().nextAll().children().addClass('over-here');
    //    //alert('hey! listen!');
    //});

    $('.accordion-container .accordion-item .accordion-item__trigger .accordion-item__icon span').html('View more');
    $('.accordion-container .accordion-item .accordion-item__trigger').click(function() {
        $(this).toggleClass('active');

        if($(this).hasClass('active')) {
            $(this).children().html('Close');
            $(this).next('.accordion-item__content').slideDown();
        } else {
            $(this).children().html('View more');
            $(this).next('.accordion-item__content').slideUp();
        }
    });

    $('.about-terms .about-terms__trigger').click(function() {
        $(this).toggleClass('active');
        $('.about-terms .about-terms__content').slideToggle();
    });

    ////tabs sections
    const tabLinks = document.querySelectorAll('ul.tabs-menu li a');

    for (let i = 0; i < tabLinks.length; i++) {
        tabLinks[i].onclick = function() {
            let target = this.getAttribute('href').replace('#', '');
            let sections = document.querySelectorAll('div.tab-content');
            for(let j=0; j < sections.length; j++) {
                sections[j].style.display = 'none';
            }
            document.getElementById(target).style.display = 'block';
            for(let k=0; k < tabLinks.length; k++) {
                tabLinks[k].removeAttribute('class');
            }
            this.setAttribute('class', 'active');
            return false;
        }
    }

    //$('input:submit').wrap('<span class="btn--wrapper"></span>');
})();