function slideNextContent(btn,className){
    btn.click(function(event) {
        let $this = $(this);
        event.preventDefault();
        $this.toggleClass(className);
        $this.next().slideToggle();
    });
}
function showContent(btn,content,className){
    btn.click(function(event) {
        let $this = $(this);
        event.preventDefault();
        $this.toggleClass(className);
        content.fadeToggle();
    });
} 

function scrollDown(){
	let top = $(document).scrollTop(); 
	if (top > 0) {
		$('#header').addClass('scroll');
	} else {
		$('#header').removeClass('scroll');
	}
}

$(function(){
	//Acts when scroll down page.
	$(window).scroll(function (event) {
		scrollDown();
	});

	$('.close-res').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');

		$('.booking-widget').stop().animate({left:'100%'},{queue:false,duration:300});
	});

	$('.language li a').click(function(){
		// $('.lang-selected').html($(this).attr('attr-appr'));
		$('.lang-selected').html($(this).text());	// Full text
		$('.language').fadeOut();
	});
	$('.lang-selected').click(function(){
		$('.language').fadeToggle();
	});

	/*MOBILE MENU NAV
	****************************************************************/
	
	//Open Navigation
	$('#menu-btn').click(function(event) {
		$('html').addClass('hide-overflow');
		$('.navigation').addClass('open');
		$('.navigation').stop().animate({left:'0px'},{duration:300});
		if($('.navigation ul li a').hasClass('.item-open')){
			$(".item-open").get(0).scrollIntoView(false);
		}
	});

	$('.close-menu').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');
		$('.navigation').removeClass('open');
		$('.navigation').stop().animate({left:'100%'},{queue:false,duration:300});
	});

	//SUBNAVIGATION

	// Open active sub-nav on load
	$('.navigation ul.submenu li .active').closest('.inner').addClass('open')
	$('.navigation ul.submenu li .active').parents().parents('li').addClass('item-open');
	$('.navigation ul.submenu li .active').closest('.sub-sub-menu').slideDown(300, function() {}).addClass('menu-open');

	//Toggle open/close on tap
	$('.navigation li a').off('click').on('click', function(event) {
		if ( $(this).parent().children(".sub-sub-menu").length > 0 ) {

            event.preventDefault();

           	if ($(this).next().hasClass('menu-open')) {
	           	$(this).closest('.inner').removeClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $(this).next().slideUp(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					} 
                }).removeClass('menu-open');	                
            } else {
	            $(this).closest('.inner').addClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $('.sub-sub-menu.menu-open').slideUp(300).removeClass('menu-open'); 
                $(this).next().addClass('menu-open').slideDown(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					}
                });
            }
        } 
    }); 

	/*CUSTOM SCRIPTS FOR THIS SITE
	****************************************************************/

	//Prevents fancybox links from opening
	//$("a.fancybox").on("click", function(e){
	//	e.preventDefault();
	//});

	// $('.fancybox-copy').click(function(){
	//     $('.overlay').css('left','100%'); 
	// });

	$('.fancybox-copy').fancybox({
        wrapCSS : 'fancybox-copy-holder',
        maxWidth: 850
    });
 
	////tabs sections 
	const tabLinks = document.querySelectorAll('ul.tabs-menu li a');

	for (let i = 0; i < tabLinks.length; i++) {
		tabLinks[i].onclick = function() {
			let target = this.getAttribute('href').replace('#', '');
			let sections = document.querySelectorAll('div.tab-content');
			for(let j=0; j < sections.length; j++) {
				sections[j].style.display = 'none';
			}
			document.getElementById(target).style.display = 'block';
			for(let k=0; k < tabLinks.length; k++) {
				tabLinks[k].removeAttribute('class');
			}
			this.setAttribute('class', 'active');
			return false;
		}
	}

	let video = document.getElementById("myVideoPlayer");
	function stopVideo(){
		video.pause();
		video.currentTime = 0;
	}

	$('.video-link').click(function(){
		$('.video-popup').fadeIn();
		document.getElementById("myVideoPlayer").play();
	});
	$('.video-popup .close-icon').click(function(){
		$('.video-popup').fadeOut();
		stopVideo();
	});

	/*END OF CUSTOM SCRIPTS
	****************************************************************/

});
